html,
body {
  padding: 0;
  margin: 0;
  font-family: Hiragino sans, sans-serif;
}

html.onsen-app {
  height: 100%;
  overflow: hidden;
}

body.onsen-app {
  height: 100%;
  overflow: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input {
  outline: none;
}

/*
 * Fixes for mapbox
 */
canvas {
  outline: none !important;
}

/*
 * Fixes for react-modal
 */
.ReactModal__Overlay {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  outline: none !important;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

/*
 * Fixes for mapbox
 */
:checked + .switch__toggle {
  background-color: #FF6069 !important;
  box-shadow: inset 0 0 0 2px #FF6069 !important;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-close-button {
  display: none;
}

/*  
 *  Fix for Mapbox cursor on markers
 */
.mapboxgl-marker:hover {
  cursor: pointer;
}

/*
 *  Fix for react-collapse library to make it animated
 */
.ReactCollapse--collapse {
  transition: height 300ms;
}

/*
 *  Prevent mobile safari from automatically zooming
 */
select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
  font-size: 16px;
}

/*
 * Fixing react-verification-code-input
 */
#react-verification-code-input-css-anchor input {
  font-size: 14px;
  font-family: Hiragino sans, sans-serif !important;
}

/*
 * Fixes/hacks for Onsen UI
 */
.page, .page__content, .page--material, .page--material__content {
  font-family: Hiragino sans, sans-serif;
}

ons-toolbar svg {
  fill: #FF6069;
}

ons-toolbar .back-button__label {
  color: #FF6069;
}

ons-toolbar.toolbar.toolbar--cover-content + .page__background + .page__content {
  padding-top: 0 !important;
}

.custom-shop-icon, #custom-shop-icon {
  filter: invert(81%) sepia(1%) saturate(971%) hue-rotate(177deg) brightness(88%) contrast(92%);
}

.custom-shop-icon--selected, .tabbar__item.active #custom-shop-icon {
  filter: invert(52%) sepia(46%) saturate(3930%) hue-rotate(322deg) brightness(103%) contrast(101%) !important;
}

/*
 * Shops app
 */
body.shop-page-onsen-tabbar-hidden ons-tabbar .tabbar ons-tab {
  display: none;
}

@media (max-width:600px) {
  body.shop-page-onsen-tabbar-hidden ons-tabbar .tabbar {
    display: none;
  }

  body.shop-page-onsen-tabbar-hidden ons-tabbar .tabbar__content {
    bottom: 0;
  }
}

@media (min-width:600px) {
  ons-toolbar {
    display: none !important;
  }

  .toolbar+.page__background {
    top: 0px !important;
  }

  .toolbar+.page__background+.page__content {
    top: 0px !important;
  }

  ons-tabbar .tabbar {
    display: none;
  }

  ons-tabbar .tabbar__content {
    bottom: 0;
  }

  body.menu-page-onsen-hack ons-navigator ons-page:not(.page--wrapper) {
    display: none;
  }

  body.menu-page-onsen-hack ons-navigator ons-page:not(.page--wrapper):last-child {
    display: block !important;
    top: 32px;
    left: 32px;
    bottom: 32px;
    width: 400px;
  }

  body.menu-page-onsen-hack ons-navigator ons-page:first-child {
    top: 0px;
    left: 0px;
    right:  0px;
    bottom: 0px;
    width: auto;
    display: block !important;
  }

  /*
   * Shops page desktop app
   */
  body.shop-page-onsen-tabbar-hack:not(.shop-page-onsen-tabbar-hidden) ons-tabbar .tabbar {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    max-width: 880px;
    margin: 0 auto;
    border-top: unset;
    justify-content: flex-end;
    background-color: transparent;
  }

  body.shop-page-onsen-tabbar-hack:not(.shop-page-onsen-tabbar-hidden) ons-tabbar .tabbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% - 50vw);
    right: calc(50% - 50vw);
    height: 54px;
    background-color: #ffffff;
  }

  body.shop-page-onsen-tabbar-hack:not(.shop-page-onsen-tabbar-hidden) ons-tabbar .tabbar ons-tab {
    width: 70px;
    flex-grow: initial;
    flex-basis: auto;
  }

  body.shop-page-onsen-hack ons-navigator > ons-page:not(.page--wrapper) {
    display: none;
  }

  body.shop-page-onsen-hack ons-navigator > ons-page:not(.page--wrapper):last-child {
    display: block !important;
    top: 32px;
    bottom: 32px;
    left: calc(50% - 200px);
    width: 400px;
  }

  body.shop-page-onsen-hack ons-navigator > ons-page:first-child {
    top: 0px;
    left: 0px;
    right:  0px;
    bottom: 0px;
    width: auto;
    display: block !important;
  }

  body.shop-page-onsen-hack ons-navigator > ons-page:first-child:not(:last-child)::after {
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  body.shop-page-onsen-hack--dialog ons-navigator > ons-page:not(.page--wrapper):last-child .page__background {
    background: transparent;
  }
}

/*
 * Payjp add card button
 */
#payjp_checkout_box input {
  width: 100% !important;
  height: 48px !important;
  border-radius: 24px !important;
}
